$nav-main-color: #42b983;
.main-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  &__links-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &__username {
    font-weight: bold;
  }
}

.main-nav a {
  font-weight: bold;
  color: #2c3e50;
}

.main-nav a.router-link-exact-active {
  color: $nav-main-color;
}

.main-nav-item {
  display: flex;
  text-decoration: none;
  margin-right: 0rem;
  span {
    margin-left: 0.1rem;
  }
}

.main-nav-item:hover {
  text-decoration: underline;
}
.main-nav__green {
  display: flex;
  gap: 30px;
  a {
    color: $nav-main-color;
    font-size: 2rem;
    font-weight: normal;
    span {
      align-self: center;
      font-size: 1.2rem;
    }
    &:hover {
      color: darken($nav-main-color, 10%);
      text-decoration: none;
    }
  }
  span {
    margin-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .main-nav {
    padding: 5px 10px;
  }
  .main-nav-item {
    &__signout {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
  .main-nav__green {
    gap: 20px;
  }
}
