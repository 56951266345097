$logo-color1: #00bc78;
$logo-color2: #005834;

.logo {
  height: 55px;
  display: flex;
  align-items: center;
  h1 {
    color: $logo-color1;
    font-weight: 500;
    font-size: 2rem;
  }
  &__text-v1 {
    font-family: "rehn-bold";
  }
  &__color2 {
    color: $logo-color2;
  }

  &__image {
    max-width: 100%;
    width: 50px;
  }
  &__text-v2 {
    font-weight: 600;
    margin: 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .logo__text {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
