.account,
.account__dark {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
  padding: 1.5rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 2rem;
  &__dark {
    flex-direction: row;
    border: none;
    border-radius: 5px;
    color: #fff;
    background: #3d3d3d;
    .account-amount {
      font-weight: normal;
      margin: 10px 0;
    }
  }
}

.account-amount {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
}

.account-amount-description {
  margin: 0;
}

.account-title {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: normal;
}

.account-content-wrapper {
  width: 100%;
  flex: 1;
}

@media (min-width: 720px) {
  .account {
    flex-direction: row;
  }

  .account-content-wrapper.cta {
    flex: 0;
  }

  .transaction-button {
    width: 200px;
  }
}
@media screen and (max-width: 479px) {
  .account {
    padding: 1.5rem 1rem;
  }
}
