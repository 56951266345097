.edit-username {
  font-size: 1rem;
  &__title {
    font-weight: normal;
    font-size: 3em;
    margin-bottom: 0.5em;
  }
  &__form {
    margin: auto;
    width: 50vw;
  }
  &__error-input {
    border: 1px solid red;
  }
  &__error-message {
    color: red;
    height: 40px;
  }
  &__input-wrapper {
    padding: 5px;
    width: 100%;

    label {
      font-size: 1em;
      margin-right: 10px;
    }
    input {
      width: 10em;
      font-size: 1em;
      padding: 0.5rem;
      border-radius: 5px;
    }
    input:disabled {
      background-color: #e7e7e7;
    }
  }
  &__buttons-wrapper {
    margin: 40px 0 0;
    display: flex;
    gap: 0.5em;
    justify-content: center;
  }
}
@media screen and (max-width: 479px) {
  label {
    margin: 0;
  }
}
