.sign-in-content {
  box-sizing: border-box;
  background-color: white;
  width: 300px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 2rem;
}
.error {
  border: 1px solid red;
}
.error-message {
  color: red;
}
.sign-in-icon {
  font-size: 5rem;
}
.password-toggle-icon {
  font-size: 1.2rem;
  position: absolute;
  right: 5px;
  bottom: 8px;
}
.fa-eye {
  right: 6px;
}

.input-remember {
  display: flex;
}

.input-remember label {
  margin-left: 0.25rem;
}

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 1rem;
}

.input-wrapper label {
  font-weight: bold;
}

.input-wrapper input {
  padding: 5px;
  font-size: 1.2rem;
}
