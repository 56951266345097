.footer {
  display: flex;
  justify-content: center;
  border-top: 2px solid #ccc;
  padding: 2rem 0 1.5rem;

  &__text {
    margin: 0;
    padding: 0;
  }
}
