@font-face {
  font-family: "rehn-bold";
  src: local("rehn-bold.woff"),
    url(./assets/fonts/rehn-bold.woff) format("woff");
}
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
a {
  text-decoration: none;
}
button:hover {
  cursor: pointer;
}
#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
}
.main {
  flex: 1;
  min-width: 320px;
}

.bg-dark {
  background-color: #12002b;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
